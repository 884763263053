import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-tab-selector-button',
  standalone: true,
  imports: [NgClass],
  template: `
    <button
      [disabled]="disabled"
      class="btn btn-wrapper"
      [ngClass]="{ active: active, 'disabled-button': disabled }"
    >
      <ng-content></ng-content>
    </button>
  `,
  styleUrl: './tab-selector-button.component.scss',
})
export class TabSelectorButtonComponent implements OnInit, OnDestroy {
  @Input() active = false;
  @Input() disabled: boolean;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    // don't emit click event when button is disabled
    // if we had disabled the pointer-events, the tooltip would not show up
    this.elementRef.nativeElement.addEventListener('click', this.captureClick, true);
  }

  private captureClick = (event: PointerEvent) => {
    if (this.disabled) {
      event.stopPropagation();
      event.preventDefault();
      event.stopImmediatePropagation();
      return false;
    }
    return true;
  };

  ngOnDestroy() {
    this.elementRef.nativeElement.removeEventListener('click', this.captureClick, true);
  }
}
